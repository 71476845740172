export default [
  {
    key: "client_name",
    label: "Name",
    visible: true
  },
  {
    key: "account",
    label: "Account",
    visible: true
  },
  {
    key: "mobile",
    label: "Mobile",
    visible: true
  },
  {
    key: "zipcode",
    label: "Zip Code",
    visible: true
  },
  {
    key: "advisor_name",
    label: "Advisor",
    visible: true
  },
  {
    key: "task",
    label: "Task",
    tdClass: "px-2",
    visible: true
  },
  {
    key: "date_t",
    label: "Date Time",
    sortable: true,
    visible: true
  },
  {
    key: "date_cool",
    label: "Last Call",
    visible: true
  },
  {
    key: "status",
    label: "Status",
    visible: true
  },
  {
    key: "comments",
    label: "Tracking",
    visible: true
  },
  {
    key: "call_data",
    label: "Calls",
    sortable: true,
    visible: true
  },
  {
    key: "operator_name",
    label: "Completed By",
    visible: true
  },
  {
    key: "updated_at",
    label: "Date of Completed",
    sortable: true,
    visible: true
  },
  {
    key: "action",
    label: "Actions",
    visible: true
  }
]