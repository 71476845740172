<template>
  <div>
    <b-modal
      modal
      v-model="modalAddTracking"
      title="ADD TRACKING"
      size="xmd"
      modal-class="modal-primary"
      scrollable
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModal"
    >
      <ValidationObserver ref="form">
        <b-row cols="1">
          <b-col>
            <b-form-group class="mt-2">
              <b-input-group prepend="Client">
                <div
                  class="form-control h-auto bg-transparent border-secondary sms-container"
                >
                  {{ callRoundUser.client_name }}
                </div>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group label="Comment">
                <b-form-textarea
                  :state="errors[0] ? false : null"
                  v-model="comment"
                  placeholder="Write new message"
                  rows="10"
                  maxlength="1000"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col>
            <b-form-group label="File">
              <b-form-file
                @change="onImageChange"
                placeholder="drop a file here"
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <b-button variant="success" @click="status==2? revisionCallRound() : saveComment()">
          <span>SAVE</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CallRoundsService from "@/views/commons/call-rounds/service/call-rounds.service";

export default {
  props: {
    status:{
      type: Number,
    },
    callRoundUser: {
      type: Object,
    },
  },
  data() {
    return {
      modalAddTracking: false,
      file: null,
      namedoc: "",
      size: "",
      comment: "",
    };
  },
  created() {
    this.modalAddTracking = true;
  },
  methods: {
    onImageChange(e) {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(e.target.files[0]);

      fileReader.onload = (e) => {
        this.file = e.target.result;
      };
      const file = e.target.files[0].name;
      const { size } = e.target.files[0];
      this.namedoc = file;
      this.size = size;
    },
    async revisionCallRound() {
      const success = await this.$refs.form.validate();
      if (!success) {
      } else {
        try {
          this.addPreloader();
          const params = {
            id: this.callRoundUser.id,
            status: this.status, //this.callRoundUser.status,
            text: this.comment,
            user_id: this.currentUser.user_id,
            namedoc: this.namedoc,
            image: this.file,
            size: this.size,
            nameprogram: this.callRoundUser.program,
            nameaccount: this.callRoundUser.account,
          };
          const data = await CallRoundsService.revisioncallround(params);
          if (data.status == 200) {
            this.$emit("reload");
            this.removePreloader();
            this.showSuccessSwal();
            this.closeModal();
          }
        } catch (e) {
          console.log(e);
          this.removePreloader();
        }
      }
    },
    async saveComment() {
      const success = await this.$refs.form.validate();
      if (!success) {
      } else {
        try {
          this.addPreloader();
          const params = {
            id: this.callRoundUser.id,
            text: this.comment,
            user_id: this.currentUser.user_id,
            namedoc: this.namedoc,
            nameaccount: this.callRoundUser.account,
            nameprogram: this.callRoundUser.program,
            image: this.file,
            size: this.size,
          };
          const data = await CallRoundsService.saveComment(params);
          if (data.status == 200) {
            this.$emit("reload");
            this.removePreloader();
            this.showSuccessSwal();
            this.closeModal();
          }
        } catch (e) {
          console.log(e);
          this.removePreloader();
        }
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>

<style></style>
