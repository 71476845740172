<template>
  <div>
    <b-modal
      modal
      v-model="modalCallCounter"
      title="COMPLETED"
      size="xmd"
      modal-class="modal-primary"
      scrollable
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModal"
    >
      <ValidationObserver ref="form">
        <b-row cols="1">
          <b-col>
            <b-form-group class="mt-2">
              <b-input-group prepend="Client">
                <div
                  class="
                    form-control
                    h-auto
                    bg-transparent
                    border-secondary
                    sms-container
                  "
                >
                  {{ callRoundUser.client_name }}
                </div>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group label="Comment">
                <b-form-textarea
                  v-model="comment"
                  :state="errors[0] ? false : null"
                  placeholder="Write new message"
                  rows="10"
                  maxlength="1000"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <b-button variant="success" @click="updateCounterCallRound">
          <span>SAVE</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CallRoundsService from "@/views/commons/call-rounds/service/call-rounds.service";

export default {
  props: {
    amount: {
      type: Number,
    },
    callRoundUser: {
      type: Object,
    },
  },
  data() {
    return {
      modalCallCounter: false,
      comment: "",
    };
  },
  created() {
    this.modalCallCounter = true;
  },
  methods: {
    async updateCounterCallRound() {
      const success = await this.$refs.form.validate();
      if (!success) {
      } else {
        try {
          this.addPreloader();
          const params = {
            account_id: this.callRoundUser.account_id,
            c_counter: this.amount,
            comment: this.comment,
            idcall: this.callRoundUser.id,
            modul_id: this.moduleId,
            user_id: this.currentUser.user_id,
          };
          const data = await CallRoundsService.updateCounterCallRound(params);
          if (data.status == 200) {
            this.$emit("reload");
            this.removePreloader();
            this.showSuccessSwal();
            this.closeModal();
          }
        } catch (e) {
          console.log(e);
          this.removePreloader();
        }
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>

<style></style>
