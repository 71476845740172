<template>
  <div>
    <b-modal
      modal
      v-model="modalCompletedRounds"
      :title="this.status == 3 ? 'UNCOMPLETED' : 'COMPLETED'"
      size="xmd"
      modal-class="modal-primary"
      scrollable
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="closeModal"
    >
      <b-row cols="1">
        <b-col>
          <b-form-group class="mt-2">
            <b-input-group prepend="Client">
              <div
                class="form-control h-auto bg-transparent border-secondary sms-container"
              >
                {{ callRoundUser.client_name }}
              </div>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Comment">
            <b-form-textarea
              v-model="comment"
              placeholder="Write new message"
              rows="10"
              maxlength="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="success" @click="revisioncallround">
          <span>{{ status == 3 ? "UNCOMPLETED" : "COMPLETED" }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CallRoundsService from "@/views/commons/call-rounds/service/call-rounds.service";

export default {
  props: {
    callRoundUser: {
      type: Object,
    },
    status: {
      type: Number,
    },
  },
  data() {
    return {
      modalCompletedRounds: false,
      comment: "",
    };
  },
  created() {
    this.modalCompletedRounds = true;
  },
  methods: {
    async revisioncallround() {
      try {
        this.addPreloader();
        const params = {
          id: this.callRoundUser.id,
          status: this.status, //this.callRoundUser.status,
          text: this.comment,
          user_id: this.currentUser.user_id,
          namedoc: "",
          image: "",
          size: "",
          nameprogram: this.callRoundUser.program,
          nameaccount: this.callRoundUser.account,
        };
        const data = await CallRoundsService.revisioncallround(params);
        if (data.status == 200) {
          this.$emit("reload");
          this.removePreloader();
          this.showSuccessSwal();
          this.closeModal();
        }
      } catch (e) {
        console.log(e);
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>

<style></style>
