export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Task",
    model: null,
    options: [
      { text: "All", value: "" },
      { text: "TRANSFER", value: "TRANSFER" },
      { text: "ID", value: "ID" },
      { text: "UB", value: "UB" },
      { text: "ID && UB", value: "ID && UB" },
      { text: "CORRESPONDENCE", value: "CORRESPONDENCE" },
      { text: "OTHERS", value: "OTHERS" },
    ],
    reduce: "value",
    selectText: "text",
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    model: null,
    options: [
      { text: "PENDING", value: 1 },
      { text: "UNDERVIEW", value: 2 },
      { text: "UNCOMPLETED", value: 3 },
      { text: "COMPLETED", value: 4 },
    ],
    reduce: "value",
    selectText: "text",
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Advisor",
    model: null,
    options: [],
    reduce: "id",
    selectText: "user_name",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Operator",
    model: null,
    options: [],
    reduce: "id",
    selectText: "user_name",
    cols: 12,
  },
];
