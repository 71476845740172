<template>
  <div>
    <b-modal
      v-model="modalTaskCallRounds"
      modal
      title="TASK"
      size="xmd"
      modal-class="modal-primary"
      scrollable
      title-tag="h3"
      hide-footer
      :no-close-on-backdrop="true"
      @hidden="closeModal"
    >
      <b-row cols="1">
        <b-col>
          <b-form-group class="mt-2">
            <b-input-group prepend="Client">
              <div
                class="form-control h-auto bg-transparent border-secondary sms-container"
              >
                {{ callRoundUser.client_name }}
              </div>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Task">
            <b-form-input v-model="callRoundUser.task" disabled />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Description">
            <b-form-textarea
              v-model="callRoundUser.description"
              placeholder="Write new message"
              rows="10"
              maxlength="1000"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group v-if="callRoundUser.route" label="File">
            <b-button
              variant="success"
              @click="downloadFile(callRoundUser.route)"
            >
              <span class="mr-1">DOWNLOAD</span>
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import CallRoundsService from "@/views/commons/call-rounds/service/call-rounds.service";

export default {
  props: {
    callRoundUser: {
      type: Object,
    },
  },
  data() {
    return {
      modalTaskCallRounds: false,
    };
  },
  created() {
    this.modalTaskCallRounds = true;
  },
  methods: {
    async dowloadUsing(IMG_URL, FILE_NAME) {
      const image = IMG_URL;
      const imageBlog = new Blob([image], {
        type: "application/jpeg",
      });
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement("a");
      anchor.href = imageURL;
      anchor.download = FILE_NAME;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(imageURL);
    },

    async downloadFile(routeName) {
      const params = {
        route: routeName,
      };
      if (routeName) {
        const response = await CallRoundsService.dowloadfilecallround(params);
        const myArray = routeName.split("/", 5);
        await this.dowloadUsing(response.data, myArray[4]);

        return response;
      }
      this.showInfoSwal("Warning", "No file to download");
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style></style>
